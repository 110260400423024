import './App.css';
import AboutMe from './aboutMe/AboutMe';

function App() {
  return (
    <div className="App">
      <div>
        <AboutMe/>
      </div>
    </div>
  );
}

export default App;
