import React from 'react';
import './AboutMe.css'
import { Route, Routes } from 'react-router-dom';

const AboutMe = () => (
  <section id="about-me">
    <h2>About Me</h2>
    <div id="bio">
      <p>Hi! I'm Aakash. Your friendly neighbourhood developer.</p>
    </div>
    <div id="profile-picture">
      <img src="profile-pic.jpeg" alt="Profile pic" />
    </div>
    <div id="skills">
      <h3>Technical Skills and Technologies</h3>
      <ul>
        <li>Backend infrastructure development</li>
        <li>APIs</li>
        <li>Microservices</li>
        <li>Event-driven systems</li>
        {/* Add more items as needed */}
      </ul>
    </div>
    <Routes>
      <Route exact path='/' component={AboutMe} />
      <Route path='/about' component={AboutMe} />
    </Routes>
  </section>
);

export default AboutMe;
