import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Header.css'

const Header = () => {
  const [lastScroll, setLastScroll] = useState(0);

  useEffect(() => {
    const header = document.querySelector("header");
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll && currentScroll > header.offsetHeight) {
        header.style.display = "none";
      } else {
        header.style.display = "flex";
      }
      setLastScroll(currentScroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);

  return (
    <header>
      <nav>
        <ul>
          <li>
              <Link to="/about">About Me</Link>
          </li>
          <li>
              <Link to="Aakash_Resume.pdf">Resume</Link>
          </li>
          <li>
              <Link to="/hobbies">Hobbies</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
